<template>
    <div>
        <a-card
            v-if="selected_build"
            style="width:100%;"
            :title="selected_build.name"
            :tab-list="[
                {
                    key: 'detail',
                    tab: 'ภาพรวม',
                },
                {
                    key: 'items',
                    tab: 'รายการของ',
                }
            ]"
            :active-tab-key="key"
            @tabChange="key => onTabChange(key, 'key')"
            >
            <BuildCard :mode="key" :build="selected_build" @refresh="refresh" />
            <a slot="extra" href="#" @click="selected_build = null">ปิด</a>
        </a-card>
        <a-input-search placeholder="ชื่องาน/บ้าน"  v-model="build_search" />
        <div v-for="project in projects" :key="project.id" class="project-wrapper">
            <a-divider orientation="left"> 
                <a-dropdown-button @click="handleButtonClick">
                    <span>{{ project.name }} ({{ project.builds.length }})</span>
                    <a-menu slot="overlay" @click="handleMenuClick">
                        <a-menu-item key="1">
                            <a-button type="primary" @click="create_build(project.id)">
                                สร้างงาน/บ้าน
                            </a-button>
                        </a-menu-item>
                        <a-menu-item key="2">
                            <a-button type="primary" @click="delete_project(project.id)">
                                ลบโครงการ
                            </a-button>
                        </a-menu-item>
                    </a-menu>
                </a-dropdown-button>
            </a-divider>
            <a-empty v-if="!project.builds.length"/>
            <div class="build-wrapper">
                <div v-for="{ id, name, description, photo, price, items }, i in filtered_builds(project.builds)" :key="id" class="build-card" >
                    <a-badge 
                        :count="`${(price/1000000).toFixed(2)} m`"
                        :number-style="{ backgroundColor: '#52c41a' }"
                        :overflow-count="10000000">
                        <a-card hoverable style="width: 300px" @click="select_build(project.builds[i])">
                            <img slot="cover" :src="photo? photo:default_house_img" />
                            <template slot="actions" class="ant-card-actions">
                                <a-tooltip>
                                    <template slot="title">เพิ่มรายการ</template>
                                    <a-icon @click="add_items(id)" type="plus-circle" />
                                </a-tooltip>

                                <a-tooltip>
                                    <template slot="title">ตัวเลือก</template>
                                    <a-dropdown>
                                        <a class="ant-dropdown-link">
                                            <a-icon key="setting" type="setting" />
                                        </a>
                                        <a-menu slot="overlay">
                                        <a-menu-item @click="remove_build(id)">
                                            <a-icon key="delete" type="delete" />ลบ
                                        </a-menu-item>
                                        <a-menu-item @click="edit_build(id, { name, description, price})">
                                            <a-icon key="edit" type="edit" /> แก้ไข
                                        </a-menu-item>
                                        </a-menu>
                                    </a-dropdown>
                                </a-tooltip>
                            </template>
                            <a-card-meta style="text-align: start;">
                                <template #description>
                                    <a-descriptions :title="name" layout="vertical">
                                        <a-descriptions-item label="รายละเอียด" :span="3" >
                                            {{ description }}
                                        </a-descriptions-item>
                                    </a-descriptions>
                                </template>
                            </a-card-meta>
                        </a-card>
                    </a-badge>
                </div>
            </div>
        </div>
        <a-divider orientation="left">
            <a-button type="primary" @click="create_project()">
                สร้างโครงการ
            </a-button>
        </a-divider>
    </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import BuilderService from '../../api/BuilderService';
import BuildCard from './BuildCard.vue';

export default {
    name: 'Projects',
    components: {
        BuildCard
    },
    props: {
        projects: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            default_house_img: 'https://www.cimbthai.com/content/dam/cimbth/personal/images/properties/default-image/properties-generic-tile.png',
            build_search: null,
            selected_build: null,
            key: 'detail',
        };
    },
    watch: {
        projects (newVal) {
            if (newVal) {
                const found = this.projects.filter(({ builds }) => builds.map(({ id }) => id).includes(this.selected_build.id)).length
                if (found) {
                    this.selected_build = this.projects.find(({ builds }) => builds.map(({ id }) => id).includes(this.selected_build.id)).builds.find(({ id }) => id === this.selected_build.id)
                } else {
                    this.selected_build = null
                }
            }
        }
    },
    methods: {
        handleMenuClick(e) {
            // console.log('click', e);
        },
        filtered_builds(array) {
            return array.filter(({ name }) => this.build_search? name.toLowerCase().includes(this.build_search.toLowerCase()):true)
        },
        sum_items(items) {
            return items.reduce((sum, item) => sum + (item.price * item.qty), 0)
        },
        onTabChange(key, type) {
            this[type] = key;
        },
        select_build(build) {
            this.selected_build = build
        },
        async refresh() {
            this.$emit('refresh', true)
        },
        create_build(id) {
            Swal.fire({
                title: 'สร้างงาน/บ้าน',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'บันทึก',
                cancelButtonText: 'ยกเลิก',
                html:
                    `<input type="text" placeholder="ชื่องาน/บ้าน" id="build_name" class="swal2-input">`+
                    `<input type="text" placeholder="รายละเอียดงาน/บ้าน" id="build_des" class="swal2-input">`+
                    `<input type="text" placeholder="ราคางาน/บ้าน" id="build_price" class="swal2-input">`,
                preConfirm: () => {
                    return {
                        project_id: id,
                        name: document.getElementById('build_name').value,
                        description: document.getElementById('build_des').value,
                        price: document.getElementById('build_price').value,
                    }
                }
            }).then(async ({ value }) => {
                if (value) {
                    const res = await BuilderService.create_model(value, 'Build')
                    this.$emit('refresh', true)
                }
            })
        },
        create_project() {
            Swal.fire({
                title: 'สร้างโครงการ',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'บันทึก',
                cancelButtonText: 'ยกเลิก',
                html:
                    `<input type="text" placeholder="ชื่อโครงการ" id="project_name" class="swal2-input">`+
                    `<input type="text" placeholder="รายละเอียดโครงการ" id="project_des" class="swal2-input">`,
                preConfirm: () => {
                    return {
                        name: document.getElementById('project_name').value,
                        description: document.getElementById('project_des').value,
                    }
                }
            }).then(async ({ value }) => {
                if (value) {
                    const res = await BuilderService.create_model(value, 'Project')
                    this.$message.success('เพิ่มรายการสำเร็จ');
                    this.$emit('refresh', true)
                }
            })
        },
        delete_project(id) {
            Swal.fire({
                title: 'ลบโครงการ',
                text: "คุณต้องการลบโครงการนี้ใช่หรือไม่",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ลบ',
                cancelButtonText: 'ยกเลิก'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const res = await BuilderService.delete_model('Project', id)
                    this.$message.success('ลบรายการสำเร็จ');
                    this.$emit('refresh', true)
                }
            })
        },
        async add_items(id) {
            Swal.fire({
                title: 'เพิ่มรายการ',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'บันทึก',
                cancelButtonText: 'ยกเลิก',
                html:
                    `<input type="text" placeholder="ชื่อ" id="item_name" class="swal2-input">` +
                    `<input type="text" placeholder="จำนวน" id="item_qty" class="swal2-input">` +
                    `<input type="number" placeholder="ราคา" id="build_price" class="swal2-input">`,
                preConfirm: () => {
                    return {
                        build_id: id,
                        name: document.getElementById('item_name').value,
                        description: document.getElementById('item_qty').value,
                        price: document.getElementById('build_price').value
                    }
                }
            }).then(async ({ isConfirmed, value }) => {
                if (isConfirmed) {
                    this.$message.success('เพิ่มรายการสำเร็จ');
                }
            })
        },
        async remove_build(id) {
            await Swal.fire({
                title: 'คุณต้องการลบรายการนี้ใช่หรือไม่?',
                text: "คุณจะไม่สามารถกู้คืนรายการนี้ได้หากลบแล้ว!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ใช่, ลบเดี๋ยวนี้!',
                cancelButtonText: 'ยกเลิก'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await axios.delete(`${this.api_url}/build/${id}`)
                    this.$message.success('ลบรายการสำเร็จ');
                }
            })
        },
        async edit_build(id, { name, description, price }) {
            await Swal.fire({
                title: 'คุณต้องการแก้ไขรายการนี้ใช่หรือไม่?',
                text: "คุณจะไม่สามารถกู้คืนรายการนี้ได้หากลบแล้ว!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'บันทึก',
                cancelButtonText: 'ยกเลิก',
                html:
                    `<input type="text" placeholder="ชื่อ" id="build_name" class="swal2-input" value="${name}"">` +
                    `<input type="text" placeholder="รายละเอียด" id="build_des" class="swal2-input" value="${description}">` +
                    `<input type="number" placeholder="ราคา" id="build_price" class="swal2-input" value="${price}">`,
                preConfirm: () => {
                    var a = document.getElementById('build_name').value
                    var b = document.getElementById('build_des').value
                    var c = document.getElementById('build_price').value

                    a = a? a:name
                    b = b? b:description
                    c = c? c:price
                    
                    return {
                        name: a,
                        description: b,
                        price: c
                    }
                }
            }).then(async ({ isConfirmed, value }) => {
                if (isConfirmed) {
                    await BuilderService.update_model(value, 'Build', id)
                    this.$emit('refresh', true)
                    this.$message.success('แก้ไขรายการสำเร็จ');
                }
            })

        }
    }
};
</script>

<style scoped lang="less">
    .build-wrapper {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: flex-start;

        .build-card {
            margin: 10px;
        }
    }
    .project-wrapper {
        margin-bottom: 50px;
    }
</style>