
import httpClient from './httpClient';

const prefix = 'students'

export default {
    async all_students() {
        const { data } = await httpClient({ requiresAuth: false }).get(`${prefix}/all`);

        return data;
    },
    async makeList({ student_id, name, hour, amount }) {
        const { data } = await httpClient({ requiresAuth: false }).post(`${prefix}/make-list`, { student_id, name, hour, amount });

        return data;
    },
    async get_list() {
        const { data } = await httpClient({ requiresAuth: false }).get(`${prefix}/get-list`);

        return data;
    },
    async newStudent({ name }) {
        const { data } = await httpClient({ requiresAuth: false }).post(`${prefix}/new-student`, { name });

        return data;
    }
}