
import httpClient from './httpClient';

const prefix = 'builder'

export default {
    async update_model(body, name, id) {
        const { data } = await httpClient({ requiresAuth: false }).put(`${prefix}/model/${name}/${id}`, body);

        return data;
    },
    async delete_model(name, id) {
        const { data } = await httpClient({ requiresAuth: false }).delete(`${prefix}/model/${name}/${id}`);

        return data;
    },
    async create_model(body, name) {
        const { data } = await httpClient({ requiresAuth: false }).post(`${prefix}/model/${name}`, body);

        return data;
    },
    async get_projects() {
        const { data } = await httpClient({ requiresAuth: false }).get(`${prefix}/projects`);
        
        return data;
    },
    async item_autocomplete(name) {
        const { data } = await httpClient({ requiresAuth: false }).put(`${prefix}/item-auto`, { name });
        
        return data;
    }
}