<template>
    <div class="panel-wrapper">
        <a href="/">กลับ</a>
        <canvas id="donut"></canvas>
        <br><br>
        <button @click="bar_switch()">ซ่อนทั้งหมด</button>
        <canvas id="bar"></canvas>
    </div>
</template>

<script>
import Axios from 'axios'
import moment from 'moment'
import Chart from 'chart.js/auto';

export default {
    name: 'Dashboard',
    data () {
        return {
            list: [],
            api_url: 'https://moneybook-app-i85r2.ondigitalocean.app/list',
            format: 'YYYY-MM-DD HH:mm:ss',
            colors: Object.values({
                red: 'rgb(255, 99, 132)',
                orange: 'rgb(255, 159, 64)',
                yellow: 'rgb(255, 205, 86)',
                green: 'rgb(75, 192, 192)',
                blue: 'rgb(54, 162, 235)',
                purple: 'rgb(153, 102, 255)',
                grey: 'rgb(201, 203, 207)'
            }),
            bar_chart: null,
            donut_chart: null
        }
    },
    methods: {
        bar_switch() {
            this.get_all_name().forEach((e, i) => {
                this.bar_chart.hide(i)
            })
        },
        async get_list() {
            const { data } = await Axios.get(`${this.api_url}?datetime=2022`)
            this.list = data
                .filter(({ name }) => name != 'เงินทอน')
                .map(({ name, created_at, amount }) => {
                    return {
                        name, amount, month: moment(created_at, this.format).format('MM/YYYY'), date: moment(created_at, this.format).format('DD/MM')
                    }
                })

            this.init_donut_chart()
            this.init_bar_chart()
        },
        get_all_name() {
            return [...new Set(this.list.map(({ name }) => name.replace(' ','')))]
        },
        get_all_months() {
            return [...new Set(this.list.map(({ month }) => month))]
        },
        init_bar_chart() {
            const labels = this.get_all_months()
            const bar_pool = this.get_all_name()
                .map((bar_name, i) => {
                    const pool = []
                    labels.forEach(label => {
                        const item = this.list.filter(({ month, name }) => label.includes(month) && (name == bar_name))
                        pool.push(item.map(({ amount }) => Math.abs(amount)).reduce((a,b) => a+b, 0))
                    })

                    return {
                            label: bar_name,
                            data: pool,
                            backgroundColor: this.colors[Math.floor(Math.random() * (this.colors.length-1 - 0 + 1) + 0)],
                        }
                })
            
            this.bar_chart = new Chart(document.getElementById('bar'), {
                type: 'bar',
                data: {
                    labels: this.get_all_months(),
                    datasets: bar_pool
                },
                options: {
                    responsive: true
                }
            })
        },
        init_donut_chart() {
            const labels = this.get_all_name()
            const pool = []
            labels.forEach(label => {
                const item = this.list.filter(({ name }) => label.includes(name))
                pool.push(item.map(({ amount }) => amount).reduce((a,b) => a+b, 0))
            })

            this.donut_chart = new Chart(document.getElementById('donut'), {
                type: 'doughnut',
                data: {
                    labels: this.get_all_name(),
                    datasets: [
                        {
                            label: 'Dataset 1',
                            data: pool,
                            backgroundColor: this.colors,
                        }
                    ]
                },
                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                    }
                },
            })
        }
    },
    mounted() {
        this.get_list()
    }
}

</script>