var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.selected_build)?_c('a-card',{staticStyle:{"width":"100%"},attrs:{"title":_vm.selected_build.name,"tab-list":[
            {
                key: 'detail',
                tab: 'ภาพรวม',
            },
            {
                key: 'items',
                tab: 'รายการของ',
            }
        ],"active-tab-key":_vm.key},on:{"tabChange":function (key) { return _vm.onTabChange(key, 'key'); }}},[_c('BuildCard',{attrs:{"mode":_vm.key,"build":_vm.selected_build},on:{"refresh":_vm.refresh}}),_c('a',{attrs:{"slot":"extra","href":"#"},on:{"click":function($event){_vm.selected_build = null}},slot:"extra"},[_vm._v("ปิด")])],1):_vm._e(),_c('a-input-search',{attrs:{"placeholder":"ชื่องาน/บ้าน"},model:{value:(_vm.build_search),callback:function ($$v) {_vm.build_search=$$v},expression:"build_search"}}),_vm._l((_vm.projects),function(project){return _c('div',{key:project.id,staticClass:"project-wrapper"},[_c('a-divider',{attrs:{"orientation":"left"}},[_c('a-dropdown-button',{on:{"click":_vm.handleButtonClick}},[_c('span',[_vm._v(_vm._s(project.name)+" ("+_vm._s(project.builds.length)+")")]),_c('a-menu',{attrs:{"slot":"overlay"},on:{"click":_vm.handleMenuClick},slot:"overlay"},[_c('a-menu-item',{key:"1"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.create_build(project.id)}}},[_vm._v(" สร้างงาน/บ้าน ")])],1),_c('a-menu-item',{key:"2"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.delete_project(project.id)}}},[_vm._v(" ลบโครงการ ")])],1)],1)],1)],1),(!project.builds.length)?_c('a-empty'):_vm._e(),_c('div',{staticClass:"build-wrapper"},_vm._l((_vm.filtered_builds(project.builds)),function(ref,i){
        var id = ref.id;
        var name = ref.name;
        var description = ref.description;
        var photo = ref.photo;
        var price = ref.price;
        var items = ref.items;
return _c('div',{key:id,staticClass:"build-card"},[_c('a-badge',{attrs:{"count":(((price/1000000).toFixed(2)) + " m"),"number-style":{ backgroundColor: '#52c41a' },"overflow-count":10000000}},[_c('a-card',{staticStyle:{"width":"300px"},attrs:{"hoverable":""},on:{"click":function($event){return _vm.select_build(project.builds[i])}}},[_c('img',{attrs:{"slot":"cover","src":photo? photo:_vm.default_house_img},slot:"cover"}),_c('template',{staticClass:"ant-card-actions",slot:"actions"},[_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v("เพิ่มรายการ")]),_c('a-icon',{attrs:{"type":"plus-circle"},on:{"click":function($event){return _vm.add_items(id)}}})],2),_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v("ตัวเลือก")]),_c('a-dropdown',[_c('a',{staticClass:"ant-dropdown-link"},[_c('a-icon',{key:"setting",attrs:{"type":"setting"}})],1),_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},[_c('a-menu-item',{on:{"click":function($event){return _vm.remove_build(id)}}},[_c('a-icon',{key:"delete",attrs:{"type":"delete"}}),_vm._v("ลบ ")],1),_c('a-menu-item',{on:{"click":function($event){return _vm.edit_build(id, { name: name, description: description, price: price})}}},[_c('a-icon',{key:"edit",attrs:{"type":"edit"}}),_vm._v(" แก้ไข ")],1)],1)],1)],2)],1),_c('a-card-meta',{staticStyle:{"text-align":"start"},scopedSlots:_vm._u([{key:"description",fn:function(){return [_c('a-descriptions',{attrs:{"title":name,"layout":"vertical"}},[_c('a-descriptions-item',{attrs:{"label":"รายละเอียด","span":3}},[_vm._v(" "+_vm._s(description)+" ")])],1)]},proxy:true}],null,true)})],2)],1)],1)}),0)],1)}),_c('a-divider',{attrs:{"orientation":"left"}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.create_project()}}},[_vm._v(" สร้างโครงการ ")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }