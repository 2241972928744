<template>
    <div id="components-layout-demo-basic">
        <a-layout style="height: 100%;">
            <a-layout-sider >
                <a-menu style="height: 100%;text-align: start;"
                        :default-selected-keys="['1']"
                        mode="inline"
                        theme="light"
                        v-model="selectedKeys"
                    >
                    <a-menu-item key="0" :disabled="true">
                        <div class="logo">
                            Prime Village
                        </div>
                    </a-menu-item>
                    <a-menu-item key="1">
                        <a-icon type="pie-chart" />
                        <span>Dashboard</span>
                    </a-menu-item>
                    <a-menu-item key="2">
                        <a-icon type="desktop" />
                        <span>Projects</span>
                    </a-menu-item>
                    <a-menu-item key="3">
                        <a-icon type="" />
                        <span>Items</span>
                    </a-menu-item>
                </a-menu>
            </a-layout-sider>

            <a-layout>
                <a-layout-header>
                    {{ topic }}
                </a-layout-header>
                <a-layout-content>
                    <div class="content-wrapper">
                        <component 
                            :is="topic"
                            :projects="projects"
                            @refresh="refresh"
                        />
                    </div>
                </a-layout-content>
                <!-- <a-layout-footer>Footer</a-layout-footer> -->
            </a-layout>
        </a-layout>
    </div>
</template>

<script>
import Dashboard from './Builders/Dashboard.vue';
import Projects from './Builders/Projects.vue';
import Items from './Builders/Items.vue';
import BuilderService from '../api/BuilderService';

export default {
    components: {
        Dashboard,
        Projects,
        Items
    },
    name: 'Builder',
    data() {
        return {
            selectedKeys: ['1'],
            topic_name: {
                '1': 'Dashboard',
                '2': 'Projects',
                '3': 'Items'
            },
            projects: [
                {
                    id: 1,
                    name: 'Prime Village',
                    description: 'This is the description',
                    photo: null,
                    builds: [
                        {
                            id: 1,
                            project_id: 1,
                            name: 'Build 1',
                            description: 'This is the description',
                            photo: null,
                            price: 1000000,
                            items: [
                                {
                                    name: 'Item 1',
                                    description: 'This is the description',
                                    price: 100,
                                    qty: 1,
                                    build_id: 1
                                },
                                {
                                    name: 'Item 2',
                                    description: 'This is the description',
                                    price: 100,
                                    qty: 1,
                                    build_id: 1
                                }
                            ]
                        }
                    ]
                }
            ]
        };
    },
    computed: {
        topic() {
            return this.topic_name[this.selectedKeys[0]];
        }
    },
    methods: {
        async refresh() {
            this.projects = await BuilderService.get_projects();
        }
    },
    mounted() {
        this.refresh()
    }
};
</script>

<style lang="less" scoped>
    .content-wrapper {
        padding: 0 50px;
        margin: 16px 0px;
        overflow-y: scroll;
    }
    #components-layout-demo-basic {
        height: 100vh;
        text-align: center;

        .ant-layout-header, .ant-layout-footer {
            background: #c8c9ca;
            color: #fff;
        }

        .ant-layout-footer {
            line-height: 1.5;
        }

        .ant-layout-sider {
            background: #626e77;
            color: #fff;
            line-height: 120px;
        }

        .ant-layout-content {
            background: rgb(255, 255, 255);
            color: #fff;
            min-height: 120px;
            line-height: 120px;
            overflow-y: scroll;
        }

        .ant-layout {
            margin-bottom: 48px;
        }

        .ant-layout:last-child {
            margin: 0;
        }
    }
</style>