<template>
    <div class="container">
        <div class="card">
            <h2>Students</h2>
            <table class="table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Remaining Hour</th>
                        <th>Remaining Day</th>
                        <th>End At</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="student in students" :key="student.id">
                        <td>{{ student.name }}</td>
                        <td>{{ student.remaining_hour }}</td>
                        <td>{{ getRemainingDay(student.remaining_hour) }}</td>
                        <td>{{ getEndAt(getRemainingDay(student.remaining_hour)) }}</td>
                        <td>
                            <button class="btn btn-primary" @click="makeList(student.id)">Make List</button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="input-group">
                <input type="text" v-model="student_name" placeholder="Student Name" class="input-text">
                <button class="btn btn-success" @click="newStudent">Add Student</button>
            </div>
        </div>

        <div class="card" style="margin-top: 24px;">
            <h2>Attendance List</h2>
            <table class="table">
                <thead>
                    <tr>
                        <th>Student</th>
                        <th>Name</th>
                        <!-- <th>Amount</th> -->
                        <th>Hour</th>
                        <th>Created At</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="list in lists" :key="list.id">
                        <td>{{ list.student.name }}</td>
                        <td>{{ list.name }}</td>
                        <!-- <td>{{ list.amount.toLocaleString() }}</td> -->
                        <td>{{ list.hour }}</td>
                        <td>{{ list.created_at }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import studentService from '@/api/StudentService.js'
import Swal from 'sweetalert2'
import moment from 'moment'

export default {
    name: 'Students',
    data() {
        return {
            students: [],
            lists: [],
            student_name: '',
        }
    },
    methods: {
        getEndAt(remaining_days) {
            return moment(this.getLastWeekend(), 'YYYY-MM-DD').add(Math.floor(remaining_days/2), 'week').add(remaining_days/2,'day').format('DD MMM')
        },
        getLastWeekend() {
            return moment().startOf('week').subtract(1, 'day').format('YYYY-MM-DD')
        },
        getRemainingDay(hour) {
            return Math.floor(hour / 4)
        },
        newStudent() {
            studentService.newStudent({ name: this.student_name })
                .then(() => {
                    this.getStudents()
                })
                .catch(error => {
                    console.log(error)
                })
        },
        getStudents() {
            studentService.all_students()
                .then(response => {
                    this.students = response
                })
                .catch(error => {
                    console.log(error)
                })
        },
        makeList(student_id) {
            Swal.fire({
                title: 'Enter Amount and Hour',
                html: `
            <input id="name" class="swal2-input" placeholder="Name">
            <input id="amount" class="swal2-input" placeholder="Amount">
            <input id="hour" class="swal2-input" placeholder="Hour">
          `,
                focusConfirm: false,
                preConfirm: () => {
                    const amount = Swal.getPopup().querySelector('#amount').value
                    const hour = Swal.getPopup().querySelector('#hour').value
                    const name = Swal.getPopup().querySelector('#name').value

                    studentService.makeList({ student_id, name, amount, hour })
                        .then(() => {
                            Swal.fire('Success', 'List Created', 'success')
                            this.getList()
                        })
                        .catch(error => {
                            console.log(error)
                        })
                }
            })
        },
        getList() {
            studentService.get_list()
                .then(response => {
                    this.lists = response
                })
                .catch(error => {
                    console.log(error)
                })
        }
    },
    mounted() {
        this.getStudents()
        this.getList()
    }
}
</script>

<style scoped>
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.card {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

h2 {
    margin-bottom: 16px;
    font-size: 1.5rem;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 16px;
}

.table th,
.table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.table th {
    background-color: #f4f4f4;
    font-weight: bold;
}

.input-group {
    display: flex;
    align-items: center;
}

.input-text {
    flex: 1;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-right: 8px;
}

.btn {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.btn-primary {
    background-color: #007bff;
    color: #fff;
}

.btn-success {
    background-color: #28a745;
    color: #fff;
}

.btn:hover {
    opacity: 0.9;
}
</style>