<template>
    <div>
        <div v-if="mode == 'detail'">
            <div>
                <a-statistic title="ราคาขาย" :value="build.price" style="text-align: start;" /><br>
                <a-row :gutter="16">
                    <a-col :span="12">
                        <a-card>
                        <a-statistic
                            title="กำไร"
                            :value="profit"
                            :value-style="{ color: '#3f8600' }"
                            style="margin-right: 50px"
                        >
                        </a-statistic>
                        </a-card>
                    </a-col>
                    <a-col :span="12">
                        <a-card>
                            <a-statistic
                                title="ต้นทุน"
                                :value="total_cost"
                                :precision="2"
                                class="demo-class"
                                :value-style="{ color: '#cf1322' }"
                            >
                            </a-statistic>
                        </a-card>
                    </a-col>
                    </a-row>
            </div>
        </div>
        <div v-if="mode == 'items'">
            <div class="add-item-wrapper">
                <a-form 
                    :form="new_item"
                    layout="inline"
                    @submit="handleSubmit">

                    <a-form-item label="ชื่อ">
                        <a-auto-complete
                            v-model="new_item.name"
                            :data-source="item_autocomplete"
                            placeholder="ชื่อรายการ"
                            @search="onSearch"
                            @change="onChange"
                        />
                    </a-form-item>
                    <a-form-item label="จำนวน">
                        <a-input-number v-model="new_item.qty" placeholder="จำนวน" />
                    </a-form-item>
                    <a-form-item label="ราคา">
                        <a-input-number v-model="new_item.price" placeholder="ราคา" />
                    </a-form-item>
                    
                    <a-form-item>
                        <a-button type="primary" html-type="submit"> + เพิ่ม
                        </a-button>
                    </a-form-item>
                </a-form>
            </div>
            <div style="height: fit-content">
                <a-table :columns="columns" :data-source="build.items">
            </a-table>
            </div>
        </div>
    </div>
</template>

<script>
import BuilderService from '../../api/BuilderService';

export default {
    name: 'Build',
    props: {
        api_url: String,
        build: {
            type: Object,
            default: () => {}
        },
        mode: {
            type: String,
            default: 'detail'
        }
    },
    computed: {
        total_cost() {
            return this.build.items.reduce((acc, item) => {
                return acc + (item.price * item.qty);
            }, 0)
        },
        profit() {
            return this.build.price - this.build.items.reduce((acc, item) => {
                return acc + (item.price * item.qty);
            }, 0)
        }
    },
    data () {
        return {
            new_item: {
                name: null,
                qty: null,
                price: null,
            },
            item_autocomplete: [],
            columns: [
                {
                    title: 'ชื่อรายการ',
                    dataIndex: 'name',
                    key: 'name',
                    scopedSlots: { customRender: 'name' },
                },
                {
                    title: 'จำนวน',
                    dataIndex: 'qty',
                    key: 'qty',
                },
                {
                    title: 'ราคา',
                    dataIndex: 'price',
                    key: 'price',
                },
                {
                    title: 'รวม',
                    key: 'total',
                    dataIndex: 'total',
                },
            ],
        }
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault();
            BuilderService.create_model({
                name: this.new_item.name,
                qty: this.new_item.qty,
                price: this.new_item.price,
                build_id: this.build.id,
                project_id: this.build.project_id,
            }, 'Item')
                .then(res => {
                    if (res) {
                        this.$message.success('เพิ่มรายการสำเร็จ');
                        this.$emit('refresh', true)
                    }
                    this.new_item = {
                        name: null,
                        qty: null,
                        price: null,
                    }
                })
        },
        async onSearch(new_item) {
            this.item_autocomplete = !new_item ? [] : [new_item, new_item.repeat(2), new_item.repeat(3)];
        },
        async onChange(value) {
            this.item_autocomplete = await BuilderService.item_autocomplete(value)
        },
    }
}
</script>

<style scoped lang="less">
    .add-item-wrapper {
        padding: 16px 0;
    }
</style>