import Vue from 'vue'
import VueRouter from 'vue-router'
import Students from '../views/Students.vue'
import Dashboard from '../views/Dashboard.vue'
import Builder from '../views/Builder.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Students',
    component: Students
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/builder',
    name: 'Builder',
    component: Builder
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
